exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alandsbanken-jsx": () => import("./../../../src/pages/ålandsbanken.jsx" /* webpackChunkName: "component---src-pages-alandsbanken-jsx" */),
  "component---src-pages-bluestep-jsx": () => import("./../../../src/pages/bluestep.jsx" /* webpackChunkName: "component---src-pages-bluestep-jsx" */),
  "component---src-pages-danske-bank-jsx": () => import("./../../../src/pages/danske-bank.jsx" /* webpackChunkName: "component---src-pages-danske-bank-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-handelsbanken-jsx": () => import("./../../../src/pages/handelsbanken.jsx" /* webpackChunkName: "component---src-pages-handelsbanken-jsx" */),
  "component---src-pages-hypoteket-jsx": () => import("./../../../src/pages/hypoteket.jsx" /* webpackChunkName: "component---src-pages-hypoteket-jsx" */),
  "component---src-pages-ica-banken-jsx": () => import("./../../../src/pages/ica-banken.jsx" /* webpackChunkName: "component---src-pages-ica-banken-jsx" */),
  "component---src-pages-ikano-bank-jsx": () => import("./../../../src/pages/ikano-bank.jsx" /* webpackChunkName: "component---src-pages-ikano-bank-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jak-medlemsbank-jsx": () => import("./../../../src/pages/jak-medlemsbank.jsx" /* webpackChunkName: "component---src-pages-jak-medlemsbank-jsx" */),
  "component---src-pages-landshypotek-jsx": () => import("./../../../src/pages/landshypotek.jsx" /* webpackChunkName: "component---src-pages-landshypotek-jsx" */),
  "component---src-pages-lansforsakringar-jsx": () => import("./../../../src/pages/länsförsäkringar.jsx" /* webpackChunkName: "component---src-pages-lansforsakringar-jsx" */),
  "component---src-pages-marginalen-bank-jsx": () => import("./../../../src/pages/marginalen-bank.jsx" /* webpackChunkName: "component---src-pages-marginalen-bank-jsx" */),
  "component---src-pages-nordea-jsx": () => import("./../../../src/pages/nordea.jsx" /* webpackChunkName: "component---src-pages-nordea-jsx" */),
  "component---src-pages-nordnet-jsx": () => import("./../../../src/pages/nordnet.jsx" /* webpackChunkName: "component---src-pages-nordnet-jsx" */),
  "component---src-pages-sbab-jsx": () => import("./../../../src/pages/sbab.jsx" /* webpackChunkName: "component---src-pages-sbab-jsx" */),
  "component---src-pages-seb-jsx": () => import("./../../../src/pages/seb.jsx" /* webpackChunkName: "component---src-pages-seb-jsx" */),
  "component---src-pages-skandia-jsx": () => import("./../../../src/pages/skandia.jsx" /* webpackChunkName: "component---src-pages-skandia-jsx" */),
  "component---src-pages-stabelo-jsx": () => import("./../../../src/pages/stabelo.jsx" /* webpackChunkName: "component---src-pages-stabelo-jsx" */),
  "component---src-pages-swedbank-jsx": () => import("./../../../src/pages/swedbank.jsx" /* webpackChunkName: "component---src-pages-swedbank-jsx" */)
}

